export default function NavTabs() {
	return {
		showTab: false,
		init() {
			const root = this.$root;
			root.addEventListener("mouseenter", () => {
				this.showTab = true;
			});
			root.addEventListener("mouseleave", () => {
				this.showTab = false;
				const overlay = this.$refs.overlay;
			});
			root.addEventListener("mousemove", (event) => {
				this.positionTabBg(event);
			});
		},

		positionTabBg(event) {
			const target = event.target;
			const overlay = this.$refs.overlay;
			if (target.classList.contains("tab")) {
				overlay.style.left = `${target.getBoundingClientRect().left}px`;
				overlay.style.width = `${
					target.getBoundingClientRect().width
				}px`;
				overlay.style.height = `${
					target.getBoundingClientRect().height
				}px`;
			}
		},
	};
}
