import { DotLottie } from "@lottiefiles/dotlottie-web";

export default function splash() {
	return {
		addSplash: this.$persist(true).using(sessionStorage),
		showSplash: this.$persist(true).using(sessionStorage),

		init() {
			if (this.showSplash) {
				this.$nextTick(() => {
					const canvas = this.$root.querySelector("canvas");

					new DotLottie({
						autoplay: true,
						loop: false,
						canvas: canvas,
						src: canvas.dataset.src,
					});
				});

				setTimeout(() => {
					this.showSplash = false;
				}, 6000);
				setTimeout(() => {
					this.addSplash = false;
				}, 6600);
			}
		},

		hideSplash() {
			this.showSplash = false;
			setTimeout(() => {
				this.addSplash = false;
			}, 600);
		},
	};
}
