import { DotLottie } from "@lottiefiles/dotlottie-web";

export default function headerLogo() {
	return {
		init() {
			const root = this.$root;
			const canvas = this.$root.querySelector("canvas");

			const lottie = new DotLottie({
				autoplay: false,
				loop: false,
				canvas: canvas,
				src: canvas.dataset.src,
			});

			setTimeout(() => {
				const halfWay = Math.floor(lottie.totalFrames / 2);
				let currentFrame = 0;

				const playListener = () => {
					currentFrame = Math.floor(lottie.currentFrame);
					if (currentFrame === halfWay) {
						lottie.pause();
					}
				};

				root.addEventListener("mouseenter", () => {
					lottie.play();
					lottie.addEventListener("frame", playListener);
				});

				root.addEventListener("mouseleave", () => {
					lottie.removeEventListener("frame", playListener);
					if (currentFrame === halfWay) {
						lottie.play();
					}
				});
			}, 600);
		},
	};
}
